<template>
  <div class="login">
    <div class="box col-pc-4 col-smobile-12 uk-margin-auto">
      <div class="top">
        <div class="flexbox col-f">
          <div class="return col-all-6">
            <router-link v-if="!registrationMethod" class="secondary" :to="{ name: 'login' }">{{
              $t('register.return-login')
            }}</router-link>
            <a class="secondary" v-if="registrationMethod" @click="registrationMethod = null">{{
              $t('actions.return')
            }}</a>
          </div>
          <div class="help col-all-6">
            <a class="secondary" target="_blank" href="//leafcad.com/support">{{ $t('actions.help') }}</a>
          </div>
        </div>
      </div>
      <form v-if="!registrationMethod">
        <div class="title">
          <h1>{{ $t('register.register-now') }}</h1>
        </div>
        <button class="primary-full col-all-12" @click.prevent="registrationMethod = 'EMAIL'">
          <i class="fas fa-envelope uk-margin-right"></i>
          {{ $t('register.register-with-email') }}
        </button>
        <button class="primary-full col-all-12 uk-margin-top" @click.prevent="registrationMethod = 'STEAM'">
          <i class="fab fa-steam uk-margin-right"></i>
          {{ $t('register.register-with-steam') }}
        </button>
      </form>
      <form class="form" v-if="registrationMethod === 'EMAIL'">
        <div class="title">
          <h1>{{ $t('register.register-with-email') }}</h1>
        </div>
        <div class="email">
          <alerts-display :errors="errors" />
          <p class="primary">{{ $t('register.enter-email-address') }} *</p>
          <CustomInput
            class="custom-input primary col-all-12"
            required="required"
            type="email"
            icon="mail"
            :placeholder="$t('register.email')"
            v-model.trim="$v.email.$model"
            :model="$v.email"
            :error="fieldsErrors.email"
          ></CustomInput>
        </div>
        <div class="password">
          <p class="primary">{{ $t('register.enter-password') }} *</p>
          <CustomInput
            class="custom-input primary col-all-12"
            required="required"
            type="password"
            icon="lock"
            :placeholder="$t('register.password')"
            v-model.trim="$v.password.$model"
            :model="$v.password"
            :error="fieldsErrors.password"
          ></CustomInput>
        </div>
        <div class="retype-password">
          <p class="primary">{{ $t('register.retype-password') }} *</p>
          <CustomInput
            class="custom-input primary col-all-12"
            required="required"
            type="password"
            icon="lock"
            :placeholder="$t('register.retype-password')"
            v-model.trim="$v.retypedPassword.$model"
            :model="$v.retypedPassword"
            :error="fieldsErrors.retypedPassword"
          ></CustomInput>
        </div>
        <div class="confirm-terms col-f">
          <label>
            <CustomInput
              class="custom-input primary"
              required="required"
              type="checkbox"
              v-model.trim="$v.terms.$model"
              :model="$v.terms"
              :error="fieldsErrors.terms"
              name="confirm-terms"
            >
              <span class="primary" style="margin-left: 5px;">
                {{ $t('register.check-to-accept-terms') }}
                <a target="_blank" href="//leafcad.com/terms">{{ $t('register.leafcad-terms') }}</a>
                *
              </span>
            </CustomInput>
          </label>
        </div>
        <div class="confirm-marketing-mails col-f">
          <label>
            <CustomInput
              class="custom-input primary"
              type="checkbox"
              v-model.trim="$v.marketingEmails.$model"
              :model="$v.marketingEmails"
              :error="fieldsErrors.marketingEmails"
              name="confirm-marketing-mails"
            >
              <span class="primary" style="margin-left: 5px;">
                {{ $t('register.check-to-accept-marketing-mails') }}
              </span>
            </CustomInput>
          </label>
        </div>
        <VueReCaptcha sitekey="6LdmJ6EUAAAAAHsxB2RbCShfFn54eJ-cYMbtoCAK">
          <button
            class="primary-full col-all-12"
            :class="{ disabled: isLoading, spinner: isLoading }"
            @click.prevent="checkForm()"
          >
            {{ $t('actions.complete') }}
          </button>
        </VueReCaptcha>
      </form>
      <form class="form" v-if="registrationMethod === 'STEAM'">
        <div class="title">
          <h1>{{ $t('register.register-with-steam') }}</h1>
        </div>
        <div class="button col-all-12">
          <a @click="loginWithSteam"
            ><img
              src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_small.png"
              alt="steam sign in"
          /></a>
        </div>
      </form>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/login/register.less"></style>

<script>
import validationMessage from '@/validationMapping'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import CustomInput from '@/components/CustomInput.vue'
import VueReCaptcha from 'vue-recaptcha'

const checkboxRequired = (value, vm) => {
  return !!value
}

export default {
  name: 'register',
  components: {
    CustomInput,
    VueReCaptcha
  },
  data: function() {
    return {
      registrationMethod: null,
      errors: [],
      fieldsErrors: {},
      isLoading: false,
      email: '',
      password: '',
      retypedPassword: '',
      terms: false,
      marketingEmails: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    },
    retypedPassword: {
      required,
      sameAs: sameAs('password')
    },
    terms: {
      checkboxRequired
    },
    marketingEmails: {}
  },
  methods: {
    loginWithSteam() {
      window.location.replace('//' + process.env.VUE_APP_API_ENDPOINT + '/auth/openid/steam')
    },
    checkForm: function() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.errors = ['FIX_ERRORS']
        this.fieldsErrors = {
          email: validationMessage(this.$v.email),
          password: validationMessage(this.$v.password),
          retypedPassword: validationMessage(this.$v.retypedPassword),
          terms: validationMessage(this.$v.terms)
        }
        this.isLoading = false
        return
      }

      this.$store
        .dispatch('auth/register', {
          email: this.email,
          password: this.password,
          retypedPassword: this.retypedPassword,
          terms: this.terms,
          marketingEmails: this.marketingEmails
        })
        .then(response => {
          this.$logRocket.identify(this.$store.state.auth.user.id, {
            name: this.$store.state.auth.user.username,
            email: this.$store.state.auth.user.email
          })
          this.$logRocket.track('Registered')

          if (!this.$route.query.redirect) {
            this.isLoading = false

            this.$router.push({ name: 'confirm' })
          } else {
            this.$store
              .dispatch('auth/createAuthToken')
              .then(response => {
                this.isLoading = false

                const url = new URL(this.$route.query.redirect)
                url.searchParams.append('a-token', response.token)

                window.location.replace(url.toString())
              })
              .catch(error => {
                this.isLoading = false
                console.error(error)

                error.graphQLErrors.map(({ extensions }) => {
                  this.errors = Object.values(extensions)
                })
              })
          }
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.errors = Object.values(extensions)

            if (this.errors.includes('EMAIL_ALREADY_USED')) {
              this.fieldsErrors.email = this.$t('errors.EMAIL_ALREADY_USED')
            } else {
              this.fieldsErrors = {
                username: true,
                email: true,
                password: true,
                retypedPassword: true
              }
            }
          })
        })
    },
    recaptcha() {
      this.$recaptcha('register').then(token => {
        console.log(token) // Will print the token
      })
    }
  }
}
</script>
